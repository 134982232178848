import { useEffect, useRef } from "react";
import { About } from "./module/about/about";
import { Contact } from "./module/contact/contact";
import { Experience } from "./module/experience/experience";
import { Footer } from "./module/footer/footer";
import { Header } from "./module/header/header";
import { Loading } from "./module/loading/loading";
import { Cool } from "./module/cool/cool";
import { Welcome } from "./module/welcome/welcom";

function App() {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const updateMousePosition = (ev: any) => {
      if (!ref.current) return;
      ref.current.style.transform = `translate(${ev.clientX}px,${
        ev.clientY + window.scrollY
      }px)`;
    };
    window.addEventListener("mousemove", updateMousePosition);
    return () => {
      window.removeEventListener("mousemove", updateMousePosition);
    };
  }, []);

  return (
    <>
      <div className="pointer" ref={ref} />
      <div className="dots" />
      <main>
        <div className="app">
          <Header />
          <div className="main">
            <Welcome />
            <About />
            <Experience />
            <Contact />
          </div>
        </div>
        <Cool />
      </main>
      <Footer />
      <Loading />
    </>
  );
}

export default App;
