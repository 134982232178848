import { CSSProperties } from "react";
import style from "./style/style.module.css";

export function About() {
  return (
    <section id="about">
      <code
        data-anim-translate-right="true"
        style={{ "--animation-delay": 44 } as CSSProperties}
      >
        {`function`} <span style={{ color: "#D4D59A" }}>{"about"}</span>
        <span style={{ color: "#CF54CD" }}>{"(){"}</span>
      </code>
      <div className={style.about}>
        <p
          data-anim-translate-right="true"
          style={{ "--animation-delay": 45 } as CSSProperties}
        >
          I am a dedicated developer specializing in building scalable and
          high-performing web applications using <strong> .NET Core</strong>,
          <strong> React</strong> with <strong> TypeScript</strong>, and
          <strong> MS SQL</strong>. I thrive on delivering seamless integrations
          between robust backends and dynamic frontends, ensuring every
          application provides an intuitive and efficient user experience. With
          expertise in <strong> Azure Portal</strong> and
          <strong> DevOps CI/CD</strong>, I streamline deployments and foster a
          development pipeline that supports agility and reliability.
        </p>
        <p
          data-anim-translate-right="true"
          style={{ "--animation-delay": 46 } as CSSProperties}
        >
          My focus is on creating maintainable, enterprise-grade solutions that
          empower businesses with innovative and reliable digital platforms.
          Combining a strong foundation in modern frameworks with cloud-first
          strategies, I craft applications that meet real-world needs with
          exceptional performance, scalability, and accessibility.
        </p>
        <p
          data-anim-translate-right="true"
          style={{ "--animation-delay": 47 } as CSSProperties}
        >
          As a full-stack developer, I excel in bridging backend logic with
          interactive frontend components. By leveraging technologies like
          <strong> .NET Core</strong>, <strong> React TypeScript</strong>, and
          <strong> MS SQL</strong>, alongside <strong> DevOps</strong> practices
          and
          <strong> Azure</strong> cloud tools, I transform complex requirements
          into sleek, user-friendly applications. Whether optimizing queries,
          refining UI interactions, or automating deployment pipelines, I
          deliver solutions that uphold the highest standards of quality and
          usability.
        </p>
      </div>
      <code
        data-anim-translate-right="true"
        style={{ color: "#CF54CD", "--animation-delay": 48 } as CSSProperties}
      >{`}`}</code>
    </section>
  );
}
