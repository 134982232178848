import { useGLTF } from "@react-three/drei";
import { useEffect, useRef } from "react";
import { Box3, Mesh, Vector3 } from "three";

export function Earth() {
  const { scene } = useGLTF("./models/stylized_planet.glb");
  const groupRef = useRef<Mesh>(null);

  useEffect(() => {
    if (groupRef.current) {
      const box = new Box3().setFromObject(groupRef.current);
      const size = new Vector3();
      box.getSize(size);

      const center = new Vector3();
      box.getCenter(center);

      groupRef.current.position.set(-center.x, -center.y, -center.z);

      const maxDimension = Math.max(size.x, size.y, size.z);
      const scaleFactor = 5 / maxDimension;
      // groupRef.current.scale.setScalar(scaleFactor);
    }
  }, []);

  return (
    <mesh ref={groupRef}>
      <primitive object={scene} />
    </mesh>
  );
}
