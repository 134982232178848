import style from "./style/style.module.css";

export function Contact() {
  return (
    <section id="contact" className={style.container}>
      <div>
        <code>
          {`function`} <span style={{ color: "#D4D59A" }}>{"contact"}</span>
          <span style={{ color: "#CF54CD" }}>{"(){"}</span>
        </code>
        <div className={style.input_container}>
          <div className={style.div}>
            <img alt="email" src="/img/email.png" className={style.img} />
            <input className={style.input} placeholder="Email" />
          </div>
          <button className={style.button}>
            <img alt="send" src="/img/send.png" className={style.send_icon} />
          </button>
        </div>
        <code style={{ color: "#CF54CD" }}>{`}`}</code>
      </div>

      <p style={{ lineHeight: "24px" }}>
        Loosely designed in
        <strong> Figma </strong> and coded in
        <strong> Visual Studio Code </strong> by yours truly. Built with
        <strong> React </strong>
        and <strong> TypeScript</strong>, featuring stunning 3D visuals powered
        by <strong> THREE.js</strong>. Deployed as an
        <strong> Azure Static Web App</strong>, with <strong> CI/CD </strong>
        pipelines managed through <strong> Azure DevOps </strong>.
      </p>
    </section>
  );
}
