import { CSSProperties } from "react";
import style from "./style/style.module.css";

export function Experience() {
  return (
    <section id="experience">
      <code
        data-anim-translate-right="true"
        style={{ "--animation-delay": 49 } as CSSProperties}
      >
        {`function`} <span style={{ color: "#D4D59A" }}>{"experience"}</span>
        <span style={{ color: "#CF54CD" }}>{"(){"}</span>
      </code>
      <div className={style.container}>
        <div className={style.content}>
          <div
            className={style.header}
            data-anim-translate-up="true"
            style={{ "--animation-delay": 50 } as CSSProperties}
          >
            <img alt="net core" src="/img/csharp.svg" className={style.icon} />
            <h2>Software Development</h2>
          </div>
          <p
            className={style.p}
            data-anim-translate-up="true"
            style={{ "--animation-delay": 51 } as CSSProperties}
          >
            Experienced in building real-time applications with C# and .NET Core
            SignalR, leveraging robust database solutions for scalable and
            responsive software development.
          </p>
          <div className={style.languages}>
            <p
              className={style.language}
              data-anim-translate-up="true"
              style={{ "--animation-delay": 53 } as CSSProperties}
            >
              .Net Core
            </p>
            <p
              className={style.language}
              data-anim-translate-up="true"
              style={{ "--animation-delay": 54 } as CSSProperties}
            >
              MS Sql
            </p>
            <p
              className={style.language}
              data-anim-translate-up="true"
              style={{ "--animation-delay": 55 } as CSSProperties}
            >
              SignalR
            </p>
            <p
              className={style.language}
              data-anim-translate-up="true"
              style={{ "--animation-delay": 56 } as CSSProperties}
            >
              PubSub
            </p>
          </div>
        </div>

        <div className={style.content}>
          <div
            className={style.header}
            data-anim-translate-up="true"
            style={{ "--animation-delay": 57 } as CSSProperties}
          >
            <img alt="net core" src="/img/azure.svg" className={style.icon} />
            <h1>Azure Expertise</h1>
          </div>
          <p
            className={style.p}
            data-anim-translate-up="true"
            style={{ "--animation-delay": 58 } as CSSProperties}
          >
            Proficient in managing CI/CD pipelines using Azure DevOps,
            streamlining build and deployment processes for consistent and
            reliable application delivery. Experienced in utilizing Azure Portal
            for resource management, monitoring, and scaling cloud-based
            solutions to meet dynamic business needs.
          </p>
          <div className={style.languages}>
            <p className={style.language}>Azure DevOps</p>
            <p className={style.language}>Azure Portal</p>
            <p className={style.language}>App Service</p>
            <p className={style.language}>Database</p>
            <p className={style.language}>Functions</p>
            <p className={style.language}>CI/ CD</p>
            <p className={style.language}>Static Web App</p>
          </div>
        </div>

        <div className={style.content}>
          <div className={style.header}>
            <img alt="net core" src="/img/react.svg" className={style.icon} />
            <h2>Frontend Development</h2>
          </div>
          <p className={style.p}>
            Proficient in crafting responsive and dynamic user interfaces using
            modern frameworks like React and TypeScript, with a focus on
            performance and seamless user experiences.
          </p>
          <div className={style.languages}>
            <p className={style.language}>React</p>
            <p className={style.language}>TypeScript</p>
            <p className={style.language}>JavaScript</p>
            <p className={style.language}>HTML & CSS</p>
          </div>
        </div>

        <div className={style.content}>
          <div className={style.header}>
            <img alt="net core" src="/img/figma.svg" className={style.icon} />
            <h2>Figma Design</h2>
          </div>
          <p className={style.p}>
            Skilled in using Figma to develop creative and functional design
            solutions, emphasizing clarity, consistency, and user engagement.
          </p>
          <div className={style.languages}>
            <p className={style.language}>UI / UX</p>
            <p className={style.language}>Prototype</p>
          </div>
        </div>
      </div>
      <code style={{ color: "#CF54CD" }}>{`}`}</code>
    </section>
  );
}
