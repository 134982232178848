import { Link } from "react-router-dom";
import style from "./style/style.module.css";
import { CSSProperties, Suspense, useEffect, useState } from "react";
import { ScrollToSection } from "../../common/function/scroll-to";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { Computer } from "../computer/computer";
import { lottieOption } from "../loading/loading";
import Lottie from "react-lottie";

export function Header() {
  const [active, setActive] = useState("home");

  useEffect(() => {
    function handleScroll() {
      const home = document.getElementById("home");
      const about = document.getElementById("about");
      const contact = document.getElementById("contact");
      const experience = document.getElementById("experience");

      if (!home) return;
      if (!about) return;
      if (!contact) return;
      if (!experience) return;

      const threshold = window.innerHeight / 1.5;
      const homeTop = home.getBoundingClientRect().top;
      const aboutTop = about.getBoundingClientRect().top;
      const contactTop = contact.getBoundingClientRect().top;
      const experienceTop = experience.getBoundingClientRect().top;

      if (homeTop < threshold && homeTop >= 0) setActive("home");
      if (aboutTop < threshold && aboutTop >= 0) setActive("about");
      else if (experienceTop < threshold && experienceTop >= 0)
        setActive("experience");
      else if (contactTop < threshold && contactTop >= 0) setActive("contact");
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className={style.header}>
      <div className={style.me}>
        <div
          className={style.canvas}
          data-anim-translate-up="true"
          style={{ "--animation-delay": 31 } as CSSProperties}
        >
          <Lottie options={lottieOption} height={"100%"} width={"200px"} />
        </div>
        <div style={{ marginLeft: "80px", marginBottom: "15px" }}>
          <a
            className={style.name}
            data-anim-translate-up="true"
            style={{ "--animation-delay": 31 } as CSSProperties}
          >
            Cagri Gulhan
          </a>
          <h3
            className={style.title}
            data-anim-translate-up="true"
            style={{ "--animation-delay": 32 } as CSSProperties}
          >
            Software Developer
          </h3>
        </div>
      </div>
      <p
        className={style.p}
        data-anim-translate-right="true"
        style={{ "--animation-delay": 34 } as CSSProperties}
      >
        Harnessing the backend power of <strong> .NET Core</strong>, the
        frontend precision of <strong> React TypeScript</strong>, and the
        scalability of
        <strong> Azure</strong> with <strong> DevOps CI/CD</strong>, I create
        next-gen digital solutions optimized for performance and seamless
        delivery.
      </p>
      <nav>
        <ul className={`${style.ul} desktop`}>
          {["home", "about", "experience", "contact"].map((item, i) => (
            <li
              key={i}
              data-anim-translate-up="true"
              data-active={active === item}
              style={{ "--animation-delay": 36 + i * 2 } as CSSProperties}
            >
              <Link
                to="#"
                onClick={(e) => {
                  setActive(item);
                  e.preventDefault();
                  ScrollToSection(item);
                }}
                data-active={active === item}
              >
                <code>
                  {`function`}{" "}
                  <span
                    className={style.function}
                  >{`${item.toLowerCase()}`}</span>
                  <span className={style.bracket}>()</span>
                </code>
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
}
