import { useEffect, useRef } from "react";
import style from "./style/style.module.css";
import lottie from "../../lottie/loading.json";
import Lottie from "react-lottie";

export const lottieOption = {
  loop: true,
  autoplay: true,
  animationData: lottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
export function Loading() {
  const div = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!div.current) return;

    setTimeout(() => {
      if (!div.current) return;
      div.current.style.opacity = "0";
    }, 2500);
  }, []);

  return (
    <div className={style.container} ref={div}>
      <Lottie
        width={"250px"}
        height={"250px"}
        options={lottieOption}
        style={{ opacity: 0.75 }}
      />
      <div className="dots" />
    </div>
  );
}
