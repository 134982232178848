import { Canvas } from "@react-three/fiber";
import { Suspense, useEffect, useRef } from "react";
import { Environment, OrbitControls } from "@react-three/drei";
import style from "./style/style.module.css";
import { FallbackLoader } from "../computer/fallback-loader";
import { Network } from "../computer/network";

export function Welcome() {
  const refText = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let textIteration = 0;
    const textInterval = setInterval(() => {
      if (!refText.current) return;

      refText.current.childNodes.forEach((element, i) => {
        const div = element as HTMLDivElement;
        if (div.getAttribute("data-state") === "up") {
          div.setAttribute("data-state", "down");
          div.style.transition = "0ms";
        }

        if (div.getAttribute("data-state") === "center") {
          div.setAttribute("data-state", "up");
        }
      });

      const currentDiv = refText.current.childNodes[
        textIteration
      ] as HTMLDivElement;
      const nextDiv = refText.current.childNodes[
        textIteration + 1
      ] as HTMLDivElement;

      if (currentDiv) {
        currentDiv.style.transition = "500ms ease-in-out";
        currentDiv.setAttribute("data-state", "center");
      }

      if (!nextDiv) textIteration = 0;
      else textIteration++;
    }, 3000);

    return () => {
      clearInterval(textInterval);
    };
  }, []);

  return (
    <div className={style.container} id="home">
      <div className={style.content}>
        <Canvas
          shadows
          camera={{
            fov: 50,
            near: 0.1,
            far: 4000,
            position: [15, 15, 3025],
          }}
        >
          {/* <primitive object={new GridHelper(100, 10)} /> */}
          {/* <primitive object={new AxesHelper(50)} /> */}
          <Suspense fallback={<FallbackLoader />}>
            <Environment preset="sunset" />
            <ambientLight intensity={0.3} />
            <Network />
            <OrbitControls
              enablePan={true}
              enableZoom={true}
              enableRotate={true}
              autoRotateSpeed={0.5}
              autoRotate={true} // Manual camera control
            />
            {/* <Stats /> */}
          </Suspense>
        </Canvas>
      </div>
      <div className={style.text} ref={refText}>
        <div data-state="down">
          <h3>
            Your partner in building sleek, modern, and responsive websites
            tailored to your needs.
          </h3>
        </div>
        <div data-state="down">
          <h3>
            Bring your vision to life with top-tier web development services
            designed for success.
          </h3>
        </div>
        <div data-state="down">
          <h3>
            Transform ideas into stunning websites—where creativity meets
            cutting-edge technology
          </h3>
        </div>
        <div data-state="down">
          <h3>
            Elevate your online presence with our expert web development
            solutions—your dream website is just a click away.
          </h3>
        </div>
      </div>
    </div>
  );
}
