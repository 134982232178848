
export const ScrollToSection = (id: string) => {
    const target = document.getElementById(id);
    if (target) {
      const targetPosition =
        target.getBoundingClientRect().top + window.scrollY - 60;
        
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
    }
  };